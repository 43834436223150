<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-center">
                <strong>{{ $lang.announcement.crud.view }}</strong>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
              {{ err_msg }}
            </CAlert>
            <div class="detail-div">
              <CRow>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.announcement.form.type }} :</span> <span>{{
                      announcement.type
                    }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.announcement.form.isMaintenance }} :</span>
                    <span>{{ announcement.isMaintenance }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.announcement.form.android }} :</span>
                    <span>{{ announcement.isAndroid }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.announcement.form.ios }} :</span> <span>{{
                      announcement.isIos
                    }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.announcement.form.web }} :</span> <span>{{
                      announcement.isWeb
                    }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.announcement.form.new_feature }} :</span>
                    <span>{{ announcement.isNewFeature }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.announcement.form.is_active }} :</span>
                    <span>{{ announcement.isActive }}</span></p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                  <p><span class="custome-labal">{{ $lang.announcement.form.appType }} :</span>
                    <span>{{ announcement.appType }}</span></p>
                </CCol>
                <!--                <CCol sm="6" md="4" lg="4">-->
                <!--                  <p><span class="custome-labal">{{ $lang.announcement.form.startDate }} :</span> <span>{{ announcement.startDate }}</span></p>-->
                <!--                </CCol>-->
                <!--                <CCol sm="6" md="4" lg="4">-->
                <!--                  <p><span class="custome-labal">{{ $lang.announcement.form.startTime }} :</span> <span>{{ announcement.startTime }}</span></p>-->
                <!--                </CCol>-->
                <!--                <CCol sm="6" md="4" lg="4">-->
                <!--                  <p><span class="custome-labal">{{ $lang.announcement.form.endDate }} :</span> <span>{{ announcement.endDate }}</span></p>-->
                <!--                </CCol>-->
                <!--                <CCol sm="6" md="4" lg="4">-->
                <!--                  <p><span class="custome-labal">{{ $lang.announcement.form.endTime }} :</span> <span>{{ announcement.endTime }}</span></p>-->
                <!--                </CCol>-->
                <CCol sm="12" md="12" lg="12">
                  <p><span class="custome-labal">{{ $lang.announcement.form.message }} :</span>
                    <span>{{ announcement.message }}</span></p>
                </CCol>
              </CRow>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {announcement} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";

Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "ViewAnnouncement",
  mixins: [Mixin],
  data() {
    return {
      module: announcement,
      messageColor: "",
      submitted: false,
      SubmitBtn: "Submit",
      err_msg: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      self: this,
      msg: "",
      announcement: {
        type: "",
        message: "",
        isMaintenance: "",
        isWeb: "",
        isAndroid: "",
        isIos: "",
        isNewFeature: "",
        isActive: "",
        appType: "",
      },
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    self.editId = this.$route.params.id;
    axios.get(this.viewUrlApi(this.module, self.editId))
        .then((response) => {
          const responseData = response.data.data;
          self.announcement.type = responseData.type;
          self.announcement.message = responseData.message;
          self.announcement.isMaintenance = responseData.isMaintenance;
          self.announcement.isAndroid = responseData.isAndroid;
          self.announcement.isIos = responseData.isIos;
          self.announcement.isWeb = responseData.isWeb;
          self.announcement.isNewFeature = responseData.isNewFeature;
          self.announcement.isActive = responseData.isActive;
          self.announcement.appType = responseData.appType;
          // self.announcement.startDate = responseData.startDate;
          // self.announcement.startTime = responseData.startTime;
          // self.announcement.endDate = responseData.endDate;
          // self.announcement.endTime = responseData.endTime;
          store.commit("showLoader", false);
        })
        .catch(function () {
          store.commit("showLoader", false);
        });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
